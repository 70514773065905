import React from 'react';
import SignIn from '../../components/SignIn/SignIn';
import Layout from '../../components/Layout/Layout';
import Auth from '../../components/Auth';

function action() {
  if (Auth.isLogged()) {
    return {
      redirect: '/',
    };
  }

  return {
    title: 'Sign In',
    chunks: ['sign-in'],
    component: (
      <Layout showFooter={false} showHeader={false}>
        <SignIn />
      </Layout>
    ),
  };
}

export default action;
