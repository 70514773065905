import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Form, Text } from 'react-form';
import PropTypes from 'prop-types';
import imgLogo from '../../markup/src/img/logo.svg';
import s from '../../markup/src/sass/app.scss';
import Auth from '../../components/Auth';
import Link from '../../components/Link/Link';
import validate from './validate';
import history from '../../history';
import App from '../App';
import readError from '../../components/Form/ReadError';
import haveError from '../../components/Form/HaveError';

class SignIn extends React.Component {
  static contextTypes = {
    fetch: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(s['sign-body']);
  }

  componentWillUnmount() {
    document.body.classList.remove(s['sign-body']);
  }

  async onSubmit(values, e, formApi) {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      await Auth.authorize(values.email, values.password);

      history.push('/work-order');
    } catch (err) {
      if (err.code === 422) {
        formApi.setFormState({
          ...formApi.getFormState(),
          errors: err.data,
        });

        this.setState({ isLoading: false });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  render() {
    return (
      <div className={s['sign-inner']}>
        <div className={`${s['sign-block-wrap']} ${s['center-block']} ${s[this.state.isLoading ? 'loading' : null]}`}>
          <Form validateOnSubmit onSubmit={this.onSubmit} validate={validate}>
            {formApi => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  formApi.setFormState({
                    ...formApi.getFormState(),
                    errors: [],
                  });
                  formApi.submitForm();
                }}
              >
                <div className={`${s['sign-block']} ${s['mb-5']}`}>
                  <header className={`${s['sign-block-header']} ${s.clearfix} ${s['mb-3']}`}>
                    <div className={s['navbar-logo']}>
                      <img className={s['navbar-logo-img']} src={imgLogo} />Pasco Machine Calendar
                    </div>
                  </header>
                  <div className={s['sign-block-inner']}>
                    <p className={`${s['text-lg']} ${s['text-primary-darker']} ${s['mb-5']}`}>
                      <i>
                        Welcome!<br />Please enter your Login/Password to continue:
                      </i>
                    </p>
                    <div className={`${s['form-group']} ${s['mb-5']} ${s[haveError(formApi.errors, 'email') ? 'form-group-danger' : null]}`}>
                      <span className={s['form-label']}>Login:</span>
                      <Text className={s['form-control']} type="text" field="email" />
                      <div className={`${s['pt-2']} ${s.clearfix}`}>
                        {haveError(formApi.errors, 'email') && (
                          <div className={s['pull-left']}>
                            <span className={`${s['text-danger']} ${s['text-sm']}`}>
                              <i>{readError(formApi.errors, 'email')}</i>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`${s['form-group']} ${s['mb-5']} ${s[haveError(formApi.errors, 'password') ? 'form-group-danger' : null]}`}>
                      <span className={s['form-label']}>Password:</span>
                      <Text className={s['form-control']} type="password" field="password" />
                      <div className={`${s['pt-2']} ${s.clearfix}`}>
                        {haveError(formApi.errors, 'password') && (
                          <div className={s['pull-left']}>
                            <span className={`${s['text-danger']} ${s['text-sm']}`}>
                              <i>{readError(formApi.errors, 'password')}</i>
                            </span>
                          </div>
                        )}
                        <div className={s['pull-right']}>
                          <Link className={s['text-sm']} to="/forgot-password">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className={s['sign-block-footer']}>
                    <div className={s.row}>
                      <div className={`${s['col-5']} ${s['col-offset-7']}`}>
                        <input disabled={this.state.isLoading} type="submit" className={`${s['btn-shade']} ${s['btn-block']}`} value="Sign In" />
                      </div>
                    </div>
                  </footer>
                </div>
              </form>
            )}
          </Form>
          <p className={`${s['mb-0']} ${s['text-xs']} ${s['text-right']} ${s['text-gray']}`}>{App.getCopyright()}</p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SignIn);
