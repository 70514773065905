import validator from 'validator';

const validate = values => {
  const errors = {};

  const email = values.email || '';
  if (validator.isEmpty(email)) {
    errors.email = 'Required';
  } else if (!validator.isEmail(email)) {
    errors.email = 'Email is invalid';
  } else {
    errors.email = null;
  }

  const password = values.password || '';
  if (validator.isEmpty(password)) {
    errors.password = 'Required';
  } else {
    errors.password = null;
  }

  return errors;
};

export default validate;
